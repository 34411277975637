@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply scrollbar-thin scrollbar-thumb-green-500 scrollbar-track-gray-700;
  }
}

body {
	@apply antialiased font-sans bg-darkBackground text-gray-200;
}

body .tooltip-custom {
  @apply bg-[#1f2226] text-white rounded-md shadow-md px-3 py-2 text-sm max-w-xs break-words;
}